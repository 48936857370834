import React from 'react';
import PropTypes from 'prop-types';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

// Modules
import Window from '../../../../../modules/window';

// Models
import GoogleSignin from '../../../../../models/internal/google_signin';

export default class Google extends React.Component {
  static propTypes = {
    width: PropTypes.string,
    shape: PropTypes.string,
    access: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
    logo_alignment: PropTypes.string,
    context: PropTypes.string,
    text: PropTypes.string,
    userType: PropTypes.string,
    onAuth: PropTypes.func.isRequired,
  }

  static defaultProps = {
    width: 400,
    shape: 'rectangular',
    access: 'offline',
    theme: 'outline',
    type: 'standard',
    logo_alignment: 'left',
    context: 'signin',
    text: 'signin_with',
  }

  render() {
    const { width, shape, access, theme, type, logo_alignment, context, text, onAuth, userType } = this.props;

    return (
      <GoogleOAuthProvider clientId={gon.GOOGLE_CLIENT_ID}>
      {
        <GoogleLogin {...{
          shape,
          access,
          width,
          theme,
          type,
          logo_alignment,
          context,
          text,
          onSuccess: (response) => {
            const data = {
              credential: response.credential,
              user_type: userType,
            };
            (new GoogleSignin(data))
              .save()
              .then((response) => {
                onAuth(response);
              })
              .catch((error) => {
                Window.flash.error(error);
              });
          },
          onError: (error) => {
            Window.flash.error(error);
          }
        }} />
      }
      </GoogleOAuthProvider>
    )
  }
}