import Model from './model';
import Event from './event';
import { faCloudUpload, faClipboardList, faPalette, faUsers, faBullhorn, faCalculatorAlt, faCogs, faArchive, faChartLine } from '@fortawesome/pro-solid-svg-icons';

export default class Opportunity extends Model {
  static NAME = 'opportunity';
  static URL = '/api/v2/opportunities';

  static contractTypes = {
    'full_time': 'Full Time',
    'part_time': 'Part Time'
  };

  static directHires = {
    'contract': 'Contract',
    'permanent': 'Permanent'
  };

  static categories = {
    it: 'IT',
    management: 'Management',
    design: 'Design',
    hr: 'HR',
    marketing: 'Marketing',
    finance: 'Finance',
    operations: 'Operations',
    sales: 'Sales',
    other: 'Other',
  };

  static categoryIcons = {
    it: faCloudUpload,
    management: faClipboardList,
    design: faPalette,
    hr: faUsers,
    marketing: faBullhorn,
    finance: faCalculatorAlt,
    operations: faCogs,
    sales: faChartLine,
    other: faArchive,
  };

  // == Instance Methods =====================================================
  isPublished() {
    return !!this.published_at;
  }

  trackView() {
    const data = {
      event_type: 'opportunity_viewed',
      event_data: JSON.stringify({
        client_request_id: this.client_request_id,
        opportunity_id: this.id,
        opportunity_title: this.title,
      }),
      description: 'Viewed Opportunity',
    };
    (new Event(data)).save()
  }
}